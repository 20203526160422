export enum LOCAL_STORAGE_KEYS {
    TOKEN = 'TOKEN'
};

export enum RESPONSE_CODES {
    SUCCESS = 1000,
    ERROR = 1305,
    UNAUTHORIZED = 401,
    MISSING_SYSTEM_PERMISSIONS = 403,
    //backend not confirmed about all the response codes
};

export enum NOTIFICATION_TYPE {
    EMAIL = "Email",
    SMS = "Sms",
    WHATSAPP = "Whatsapp",
};

export enum TICKET_TYPE {
    RAISE_TICKET = "RaiseTicket",
    CLOSE_TICKET = "CloseTicket",
    REOPEN_TICKET = "ReopenTicket",
};

export enum FILE_TYPES {
    PDF = 'pdf',
    JPG = 'jpg',
    JPEG = 'jpeg',
    AVIF = 'avif',
    PNG = 'png',
    CSV = 'csv',
    XLSX = 'xlsx',
    MP4 = 'mp4',
    MKV = 'mkv',
    AVI = 'avi',
    MOV = 'mov',
    MP3 = 'mp3',
    DOCX = 'docx',
    M4A = 'm4a',
}

export enum KYC_DOCUMENT_TYPE {
    AADHAAR = 'AADHAAR',
    PASSPORT = 'PASSPORT',
    PAN_CARD = 'PAN_CARD',
    VOTER_ID = 'VOTER_ID',
    DRIVING_LISCENSE = 'DRIVING_LISCENSE'
}

export enum VEHICLE_TYPES {
    BIKE = "BIKE",
    AUTO = "AUTO",
    CAR = "CAR",
    HEAVY = "HEAVY"
}

export enum VISITOR_TYPE {
    GUEST = "GUEST",
    DELIVERY = "DELIVERY",
    CAB = "CAB",
    SERVICES = "SERVICES",
    HELPER = "HELPER",
    STAFF = "STAFF",
}

export enum GENDER {
    MALE = 'Male',
    FEMALE = 'Female',
    OTHER = 'Other',
}

export enum DAYS {
    MONDAY = 'Monday',
    TUESDAY = 'Tuesday',
    WEDNESDAY = 'Wednesday',
    THURSDAY = 'Thursday',
    FRIDAY = 'Friday',
    SATURDAY = 'Saturday',
    SUNDAY = 'Sunday'
}

export enum MONTHS {
    JANUARY = 'January',
    FEBRUARY = 'February',
    MARCH = 'March',
    APRIL = 'April',
    MAY = 'May',
    JUNE = 'June',
    JULY = 'July',
    AUGUST = 'August',
    SEPTEMBER = 'September',
    OCTOBER = 'October',
    NOVEMBER = 'November',
    DECEMBER = 'December'
}

export enum FACING {
    EAST = 'East',
    WEST = 'West',
    NORTH = "North",
    SOUTH = "South",
    NORTH_EAST = "North East",
    SOUTH_EAST = "South East",
    NORTH_WEST = "North West",
    SOUTH_WEST = "South West"
}

export enum FLAT_SHARE {
    BUILDER = "Builder",
    LANDLORD = "Landlord"
}

export enum FLAT_STATUS {
    INACTIVE = "Inactive",
    HANDOVER = "Handover",
    EMPTY = "Empty",
    OWNER = "Owner",
    TENANT = "Tenant"
}

export enum CUSTOMER_TYPE {
    ORG = 'ORG',
    INDIVIDUAL = 'INDIVIDUAL',
    POC = 'POC',
    GPA = 'GPA',
    DRAFT = 'DRAFT',
}

export enum CITIZENSHIP_STATUS {
    INDIAN = 'Indian',
    NRI = 'NRI',
    FOREIGNER = 'Foreign Nationals'
}


export enum ORG_TYPE {
    REGISTERED_PARTNERSHIP = 'registered partnership',
    LIMITED_LIABILITY_PARTNERSHIP = 'limited liability partnership',
    COMPANY = 'company'
}

export enum ORG_RELATION {
    BOARD = 'board',
    PARTNERS = 'partners'
}

export enum ORG_IDENTITY_TYPE {
    SL_NO = 'slNo',
    LLP_IN = 'llpIn',
    CIN = 'cin'
}

export enum ASSIGNMENT_PREFERENCE {
    MANUALLY_ASSIGN = 'Manually assign at all times',
    AUTO_ASSIGN_WHEN_SUPERVISORS_UNAVAILABLE = 'Auto assign only when supervisors are unavailable',
    AUTO_ASSIGN_ALL_TICKETS = 'Auto assign all tickets'
}

export enum HELPDESK_TICKETS_STATUS {
    OPEN = 'Open',
    CLOSED = 'Closed'
}

export enum HELPDESK_TICKETS_CHAT_ACTIONS {
    TICKET_CREATED = 'Ticket Created',
    COMMENTED = 'Commented',
    TICKET_ASSIGNED = 'Ticket Assigned',
    MATERIALS_REQUIRED = 'Materials Required',
    RESIDENT_UNAVAILABLE = 'Resident Unavailable',
    APPROVAL_REQUIRED = 'Approval Required',
    TICKET_ESCALATED = 'Ticket Escalated',
    CATEGORY_UPDATED = 'Category Updated',
    TICKET_REASSIGNED = 'Ticket Re-Assigned',
    TICKET_CLOSED = 'Ticket Closed',
    TICKET_REOPENED = 'Ticket Re-Opened'
}

export enum HELPDESK_TICKETS_CHAT_SUB_ACTIONS {
    RESIDENT_TICKET_CREATED = 'Resident Ticket Created',
    STAFF_TICKET_CREATED = 'Staff Ticket Created',
    SYSTEM_COMMENTED = 'System Commented',
    RESIDENT_COMMENTED = 'Resident Commented',
    STAFF_COMMENTED = 'Staff Commented',
    SYSTEM_ASSIGNED = 'System Assigned',
    STAFF_ASSIGNED = 'Staff Assigned',
    RESIDENT_CLOSED = 'Resident Closed',
    ISSUE_RESOLVED = 'Issue Resolved',
    INVALID_TICKET = 'Invalid Ticket',
    ISSUE_NOT_FOUND = 'Issue Not Found',
    NOT_IN_SCOPE = 'Not In Scope',
    NO_RESPONSE_FROM_RESIDENT = 'No Response from Resident',
    AUTO_CLOSED = 'Auto Closed',
    RESIDENT_REOPENED = 'Resident Re-Opened',
    STAFF_REOPENED = 'Staff Re-Opened'
}

export enum HELPDESK_TICKETS_MESSAGE_TYPE {
    TEXT = 'Text',
    ATTACHMENT = 'Attachment'
}

export enum HELPDESK_SOCKET_EVENTS {
    JOIN_ROOM_EVENT = 'joinRoomEvent',
    LEAVE_ROOM_EVENT = 'leaveRoomEvent',
    NEW_HELPDESK_CHAT_MESSAGE_EVENT = 'newHelpdeskChatMessageEvent',
    HELPDESK_TICKET_STATUS_UPDATE = 'helpdeskTicketStatusUpdate'
}

export enum SHARED_SOCKET_EVENTS {
    STAFF_DELETED_EVENT = 'staffDeletedEvent',
    PERMISSIONS_UPDATED_EVENT = 'permissionsUpdatedEvent',
}

export enum TURN_AROUND_TIME_TYPE {
    DAYS = 'days',
    HOURS = 'hours'
}

export enum NAVIGATION_ROUTES {
    QUICK_CONTACTS = 'Quick Contacts',
    RENOVATION_WORKS = 'Renovation Works',
    VIOLATION_SETUP = 'Violation Setup',
    VIOLATION_TICKETS = 'Violation Tickets',
    ROLES_AND_DEPARTMENTS = 'Roles & Departments',
    STAFF_DIRECTORY = 'Staff Directory',
    HELPERS = 'Helpers',
    GATES = 'Gates',
    DEVICES = 'Devices',
    PATROL_LOGS = 'Patrol Logs',
    PATROL_SHIFTS = 'Patrol Shifts',
    WORK_LOGS = 'Work Logs',
    WORK_PACKAGES = 'Work Packages',
    ASSETS = 'Assets',
    WORK_SCHEDULER = 'Work Scheduler',
    ISSUES = 'Issues',
    LOCATIONS = 'Locations',
    SECURITY_ALERTS = 'Security Alerts',
    ACCESS_LOGS = 'Access Logs',
    PARCEL_LOGS = 'Parcel Logs'
}





export enum LANGUAGES {
    "Hindi" = "Hindi",
    "English" = "English",
    "Telugu" = "Telugu",
    "Tamil" = "Tamil",
    "Marathi" = "Marathi",
    "Malayalam" = "Malayalam",
    "Kannada" = "Kannada"
}